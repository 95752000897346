.settings-view {
  overflow-y: scroll;
  width: 100%;
  height: 100%;

  &-container {
    &-title {
      font-weight: 600;
      font-size: 28px;
      line-height: 3 6px;
      letter-spacing: -0.04em;
      color: #171725;
    }

    &-sub-title {
      margin-top: 30px;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      margin-left: 15px;
      /* identical to box height, or 133% */

      letter-spacing: -0.03em;

      /* Gray / gray-8 */

      color: #595959;
    }

    &-item {
      background: #fafafb;
      border-radius: 15px;
      padding: 30px;
      margin: 20px 20px 0px 0px;
      min-height: 300px !important;

      &-title {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #171725;
        height: 50px;
      }

      &-row {
        margin-top: 35px;
        border-bottom: 1px solid #e2e2ea;
        height: 40px;
      }

      &-label {
        font-size: 14px;
        color: #171725;

        &-bold {
          font-weight: 500;
        }
      }
    }
  }
}

.settings-view::-webkit-scrollbar {
  display: none;
}
