html {
  background-color: #f1f1f5;
}

.super-admin-create-course {
  overflow-y: scroll;
  padding: 0px;
  height: 100vh;
  background-color: #f1f1f5;

  &-publish-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    padding: 120px 0px;
    &-big-text {
      margin-top: 30px;
      margin-bottom: 16px;
      letter-spacing: -0.04em;
      font-family: 'poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 44px;
      color: #44444f;
    }

    &-text {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      font-family: 'poppins';
      color: #44444f;
      width: 90vh;
      text-align: center;
    }

    &-actions {
      margin-top: auto;
      display: flex;
      align-items: center;
      padding-top: 40px;

      &-dashboard {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.03em;
        color: #92929d;
        margin-left: 40px;
        cursor: pointer;
      }
    }
  }

  &-content {
    &-delete-course {
      &-header {
        display: flex;
        align-items: center;
        &-title {
          margin-bottom: 6px;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #f5222d;
        }
      }
    }

    &-description {
      align-items: center;
      margin: 100px 52px 0px 52px;
      overflow: hidden;
      height: 100vh;

      &-content {
        display: flex;
        width: 100%;

        &-edit-description {
          width: 50%;
        }

        &-course-content {
          width: 50%;
          margin-left: 30px;
        }
      }

      &-header {
        margin-bottom: 52px;
        display: flex;
        align-items: center;
      }

      &-course-name {
        max-width: 300px;
        align-items: center;
        display: flex;
        flex: 1;
        &-text {
          font-family: 'poppins';
          font-weight: 600;
          font-size: 28px;
          line-height: 36px;
          letter-spacing: -0.04em;
          color: #000000;
        }
      }
    }

    &-content {
      display: flex;
      height: 100%;
      overflow: scroll;

      &-exam {
        &-title {
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 28px;
          /* identical to box height, or 140% */

          letter-spacing: -0.02em;

          /* Gray / gray-10 */

          color: #171725;
        }
      }

      &-quiz {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 100px;
        margin-top: 60px;
        &-settings {
          padding: 0 10%;
          align-items: center;
          margin: 90px 0px 20px 0px;
        }
        &-modal {
          &-completion {
            background: #f1f1f5;
            border-radius: 15px;
            padding: 30px;
            margin-top: 15px;

            &-title {
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              margin-bottom: 30px;
            }
          }
        }
        &-questions {
          width: 80%;
          border-radius: 15px;
          margin: 10px 0px;
          &-question {
            &-container {
              margin-top: 10px;
              padding: 20px;
              border-radius: 15px;
              background: white;
              width: 100%;
              min-height: 175px;
              justify-content: center;
              align-items: center;
              display: flex;
              flex-direction: column;
            }
            &-header {
              width: 100%;
              display: flex;
              align-items: center;
              padding: 20px 12px;
            }

            &-header * {
              margin: 2.5px;
            }

            &-title {
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              font-family: 'poppins';
              color: #171725;
              flex: 1;
            }
          }

          &-answer {
            &-header {
              background: #f1f1f5;
              border-radius: 15px;
              display: flex;
              align-items: center;
            }

            &-header * {
              margin: 2.5px;
            }

            &-title {
              font-style: normal;
              font-weight: 550;
              font-size: 14px;
              line-height: 24px;
              font-family: 'poppins';
              color: #171725;
              flex: 1;
            }

            background: #f1f1f5;
            border-radius: 15px;
            order: 0;
            margin: 10px 0px;
            width: 100%;
            padding: 20px 15px;
            height: 70px;
          }
        }

        &-add-question {
          cursor: pointer;
          width: 80%;
          display: flex;
          background: #ffffff;
          border-radius: 15px;
          justify-content: center;
          align-items: center;
          height: 80px;
          &-text {
            cursor: pointer;
            font-family: 'poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 28px;
            letter-spacing: -0.01em;
            color: #171725;
          }
        }

        &-add-answer {
          cursor: pointer;
          width: 100%;
          display: flex;
          background: #f1f1f5;
          border-radius: 15px;
          justify-content: center;
          align-items: center;
          height: 70px;

          &-text {
            cursor: pointer;
            font-family: 'poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 28px;
            letter-spacing: -0.01em;
            color: #171725;
          }
        }

        &-add-explanation {
          cursor: pointer;
          width: 100%;
          display: flex;
          background: #f1f1f5;
          border-radius: 15px;
          justify-content: center;
          align-items: center;
          height: 70px;
          margin-top: 10px;

          &-text {
            cursor: pointer;
            font-family: 'poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 28px;
            letter-spacing: -0.01em;
            color: #171725;
          }
        }
      }

      &-container {
        flex: 10;
        overflow: scroll;
        &-video {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 60px;
          margin-bottom: 100px;
          &-tabs {
            display: flex;
            align-items: center;
            width: 90%;
            justify-content: flex-start;
            margin: 10px 0px;
            border-bottom: 1px solid #d5d5dc;
            padding: 15px 5px;
            &-text {
              margin-right: 20px;
              font-family: 'poppins';
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 28px;
              /* identical to box height, or 175% */
              cursor: pointer;
              color: #595959;
              letter-spacing: -0.01em;

              &-selected {
                margin-right: 20px;
                font-family: 'poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 28px;
                /* identical to box height, or 175% */
                cursor: pointer;
                color: #f99820;
                letter-spacing: -0.01em;
              }
            }
          }

          &-additional-text {
            width: 90%;
          }

          &-chapters {
            width: 90%;
            &-item {
              &-timestamp {
                cursor: pointer;
                display: flex;
                margin-right: 30px;
                &-text {
                  font-size: 14px;
                  line-height: 24px;
                  color: #b5b5be;
                  margin-left: 10px;
                }
              }

              &-editable {
                margin: 10px 0px;
                width: 100%;
                background-color: #ffffff;
                align-items: center;
                display: flex;
                padding: 15px 30px;
                border-radius: 15px;
              }
              margin: 10px 0px;
              width: 100%;
              background-color: #ffffff;
              justify-content: center;
              align-items: center;
              display: flex;
              padding: 15px 30px;
              border-radius: 15px;
            }

            &-add {
              margin: 10px 0px;
              width: 100%;
              background-color: #ffffff;
              justify-content: center;
              align-items: center;
              display: flex;
              padding: 15px 30px;
              border-radius: 15px;
              cursor: pointer;
              &-text {
                cursor: pointer;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                color: #92929d;
              }
            }
          }
        }
      }
    }
  }

  &-footer {
    &-container {
      position: fixed;
      bottom: 0;
      width: 100%;
      align-items: center;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      height: 74px;
      background: #ffffff;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    }

    &-item {
      margin: 0px 15px;
      cursor: pointer;
      font-family: 'poppins';
      color: #92929d;
      font-size: 14px;
      &:hover {
        color: #595959;
      }
    }
    &-active-item {
      margin: 0px 15px;
      cursor: pointer;
      font-family: 'poppins';
      color: #f99820;
      font-size: 14px;
    }
  }
}

.super-admin-create-course-content-content-container::-webkit-scrollbar {
  display: none;
}

input[type='file'] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  margin-right: 20px;
  cursor: pointer;
}

#complok-course-edit-content-components-navigation::-webkit-scrollbar {
  display: none;
}
