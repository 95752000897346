@import './Certificates/Certificates.less';
@import './Course/CourseComponents/CourseComponents.less';

.coursedirectory {
  padding: 75px 30px;
  @media screen and (max-width: 768px) {
    padding: 75px 14px;
  }

  overflow-y: scroll;
  height: 100%;
}

#complok-courseinformation-navigation {
  & #complok-coursecontent-navigation {
    padding: 0;
  }
  & #complok-coursecontent-navigation-header > .ant-typography {
    font-size: 30px;
  }
}

.coursedirectory::-webkit-scrollbar {
  display: none;
}

#complok-learner-courseinfo-banner-smallpercentage {
  display: block;
}

.courseinfo-banner-ghost-percentage {
  position: absolute;
  right: 0;
  top: 50%;
  z-index: 5;
  opacity: 0.1;

  font-size: 20em;
  font-weight: 600;
  line-height: 0;
}

@media screen and (max-width: 768px) {
  // Header
  .complok-header-navigation,
  #complok-header-role-selector,
  #complok-coursecontent-quiz-result-banner-percent {
    display: none;
  }

  // CourseDir
  .complok-coursedirectory-list-items {
    flex-direction: column;
  }

  // Course Info screen
  #complok-learner-courseinfo {
    padding: 20px 0 100px 0 !important;

    &-banner-smallpercentage {
      display: block;
    }
  }

  .courseinfo-banner {
    border-radius: 0 !important;
    padding: 15px;
    overflow: hidden;

    &-action {
      margin: 30px 0px 20px 0px;
    }
    &-info {
      margin: 0 !important;
      padding: 0 !important;
      min-height: 200px;
      justify-content: start !important;
      align-items: start !important;
      width: 75%;
      z-index: 10;
    }
    &-ghost-percentage {
      left: -15px;
      top: 25%;
      font-size: 196px !important;
    }
  }

  // QuizEnding
  #complok-header-note {
    display: none !important;
  }

  // Content
  #complok-coursecontent {
    display: grid;
    height: 100vh;
    width: 100vw;
    grid-template-columns: 0 1fr 0;
    grid-template-rows: 60px 1fr;
    place-items: flex-start;
    overflow: hidden;

    &-main {
      padding: 0;
      padding-top: 0;
    }

    &.open-menu {
      grid-template-columns: 0 1px 1fr;
    }
  }
  .menu-closed {
    position: absolute;
    top: 64px !important;
    right: 0 !important;
    height: 64px !important;
    width: 32px !important;
  }

  // Quiz
  #complok-coursecontent-quiz-result-threshold {
    padding: 15px;
  }
}

.ant-btn {
  font-weight: 500;
}
