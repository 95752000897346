.module {
  &-list {
    &-item {
      border-radius: 15px;
      min-height: 54px;
      background-color: #f1f1f5;
      margin-bottom: 10px;

      &-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 15px;

        &-title {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          height: 24px;
        }

        &-arrow {
          display: flex;
          flex-direction: row;
        }

        &:hover {
          cursor: pointer;
        }
      }

      &-closed ~ &-contents {
        display: none;
      }

      &-contents {
        padding-left: 15px;
        padding-bottom: 20px;
        overflow-y: hidden;

        &-item {
          color: #44444f;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}
