.complok-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin: 15px 0;
  padding: 20px 30px;
  border-radius: 15px;

  min-height: 68px;
  background-color: #fff;

  &:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  &:hover &-content-title {
    color: #171725;
  }
  &:hover &-actions-main {
    opacity: 1;
  }

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-title {
      color: #44444f;
      font-size: 16px;
      font-weight: 600;
    }
  }
  &-actions {
    &-main {
      display: flex;
      flex-direction: column;
      justify-content: center;
      opacity: 0;
      transition-property: opacity;
      transition-duration: 250ms;
    }
    //&-secondary {}
  }
}
