$colors: (
  'primary': (
    default: #f99820,
    hover: #d77804,
    disabled: #fccc90,
  ),
  'secondary': (
    default: #92929d,
    hover: #595959,
    disabled: #d5d5dc,
  ),
  'danger': (
    default: #f5222d,
    hover: #cf1322,
    disabled: #ffa39e,
  ),
);

.slide-up-leave {
  display: none !important;
}
html {
  background-color: #f1f1f5;
}
body {
  font-family: 'poppins';
}

html {
  background-color: #f1f1f5;
}

.super-admin-course-view {
  overflow-y: scroll;
  padding: 0px;
  height: 100vh;
  background-color: #f1f1f5;
  padding: 100px 75px;

  &-filter {
    &-trigger {
      margin-right: 20px;
      background: #ffffff;
      border: 1px solid #d5d5dc;
      box-sizing: border-box;
      border-radius: 10px;
      --antd-wave-shadow-color: map-get(map-get($colors, 'secondary'), 'hover') !important;

      color: map-get(map-get($colors, 'secondary'), 'default');
      border-color: map-get(map-get($colors, 'secondary'), 'default');

      &:hover,
      &:focus,
      &:active {
        color: map-get(map-get($colors, 'secondary'), 'hover');
        border-color: map-get(map-get($colors, 'secondary'), 'hover');
      }
    }
  }

  &-header {
    display: flex;
    align-items: center;

    &-text {
      font-family: 'poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 36px;
      letter-spacing: -0.04em;
      color: #000000;
      flex: 1;
    }
  }

  &-courses {
    margin-top: 50px;

    &-header {
      font-family: 'poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.02em;
      color: #595959;
    }
    &-items {
      display: flex;
      flex-wrap: wrap;
      padding-left: 0;

      &-item {
        list-style: none;
        flex: 0 0 33.333333%;
        padding-right: 30px;
      }
    }
  }
}
