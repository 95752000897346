#complok-course-edit {
  height: 100%;
  width: 100%;
  background-color: #f1f1f5;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 60px 1fr 74px;
  &-content {
    grid-column: 1/2;
    grid-row: 2/3;

    overflow: overlay;
    display: grid;

    &-description {
      padding-bottom: 50px;
      padding: 0 52px;
      padding-top: 40px;
      &-title {
        display: flex;
        align-items: center;
        &:hover {
          cursor: pointer;
        }
      }
    }

    &-components {
      &-navigation {
        margin: 0;
        width: 400px;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
    &-settings {
      padding: 0 52px;
      padding-top: 40px;
      &-title {
        margin-bottom: 66px;
      }
      &-options {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        & > .ant-card {
          height: 150px;
          width: 500px;
          border-radius: 30px;
        }
      }
    }
    &-publish {
      display: grid;
      display: grid;
      justify-items: center;
      gap: 30px;
      align-content: center;
      &-text {
        display: grid;
        justify-items: center;
        & > * {
          color: #44444f;
        }
      }
    }
  }
  &-steps {
    background-color: #fff;
    grid-column: 1/2;
    grid-row: 3/4;
    display: grid;
    place-items: center;
    padding: 20px 20%;
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  }
}

#complok-course-edit-content-components-navigation ::-webkit-scrollbar {
  display: none;
}
