.login-view {
  width: 100%;
  height: 100%;

  &-header {
    padding: 20px;
    width: 100%;

    &-langauge-container {
      display: flex;
    }

    &-langauge-prefix {
      margin-right: 5px;
      color: #b5b5be;
      font-weight: 600;
      line-height: 20px;
      font-size: 12px;
    }

    &-langauge-value {
      color: #171725;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
    }
  }

  &-mobile {
    padding: 5px 20px;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-form {
      width: 100%;

      &-input {
        width: 90%;
      }
    }
    &-icon-container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &-icon-container {
    width: 50%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-form {
    height: 90%;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > .ant-form {
      max-width: 475px;
    }

    &-input {
      padding: 8px 12px;
      background: #ffffff;
      border: 1px solid #d5d5dc;
      box-sizing: border-box;
      border-radius: 10px !important;
    }

    &-forgot-password {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #172c4d;
      cursor: pointer;
    }
  }

  //background: radial-gradient(circle farthest-corner at -20% 30%, #172b4d calc(min(50%, 50vw)), transparent 10.1%);

  @media screen and (min-width: 1300px) {
    background: radial-gradient(circle farthest-corner at -30vw 30%, #172b4d 55%, transparent 55.1%);
  }
  @media screen and (min-width: 900px) and (max-width: 1300px) {
    background: radial-gradient(circle farthest-corner at -30vw 30%, #172b4d 55%, transparent 55.1%);
  }
  @media screen and (max-width: 900px) {
    background: radial-gradient(circle farthest-corner at -60% 30%, #172b4d 55%, transparent 55.1%);
    display: flex;
    flex-direction: column;
  }
}
