#superadmin-certificates {
  padding-top: 60px;

  &-header {
    padding: 20px 30px;
  }
  &-list {
    padding: 20px 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    & .certificate-list-item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 150px;
      width: 250px;

      margin: 15px;
      //border-radius: 15px;

      //background-color: orange;
      border: 5px solid #f99820;
      padding: 15px;
      &-header {
        display: flex;
        justify-content: space-between;
        //padding: 15px;
      }
      &-content {
      }
      &-footer {
        //border-bottom-left-radius: 15px;
        overflow: hidden;
      }
    }
  }

  & {
  }
}
