.course-content-list {
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  height: 100%;
  width: 100%;
  overflow-y: scroll;

  padding: 30px;
  margin-right: 5px;

  max-height: 65vh;

  /* width */
  &::-webkit-scrollbar {
    width: 6px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    display: none;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #cfcfcf;
    border-radius: 6px;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #cfcfcf;
  }
  &-content {
    margin-top: 10px;
  }
}

.course-content {
  overflow: scroll;
  float: right;
  padding: 30px 15px 100px 15px;
  background-color: #ffffff;
  margin-top: 50px;
  width: 30%;

  &-closed {
    height: 45px;
    position: absolute;
    right: 0;
    margin-top: 70px;
    padding: 10px 15px;
    background-color: #fff;
    opacity: 0.5;
    border-radius: 20px 0 0 20px;
    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }

  &-header {
    margin: 10px 0px 20px;
    font-family: 'poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.02em;
    color: #000000;
    display: flex;
  }

  &-close {
    float: left;
  }

  &-module {
    background: #f1f1f5;
    padding: 15px;
    margin: 10px 0px;
    border-radius: 10px;

    &-container {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
    }

    &-title {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      font-family: 'poppins';
      color: #171725;
      flex: 1;
    }
  }

  &-subject {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    &-title {
      margin-right: 10px;
      font-family: 'poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 28px;
      letter-spacing: -0.01em;
      color: #171725;
      cursor: pointer;

      &-selected {
        margin-right: 10px;
        font-family: 'poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 28px;
        letter-spacing: -0.01em;
        color: #f99820;
        cursor: pointer;
      }
    }
  }

  &-add-module {
    background-color: #d5d5dc;
    border-radius: 10px;
    width: 100%;
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    cursor: pointer;
  }

  &-add-subject {
    background-color: #d5d5dc;
    border-radius: 10px;
    width: 100%;
    height: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    cursor: pointer;
  }
}
