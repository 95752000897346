.forgot-password-view {
  background: #ffffff;
  height: 100%;

  &-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 70vh;

    &-form {
      // padding: 0 40%;
      width: 330px;
      &-input {
        padding: 8px 12px;
        background: #ffffff;
        border: 1px solid #d5d5dc;
        box-sizing: border-box;
        border-radius: 10px;
      }

      &-forgot-password {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #172c4d;
        cursor: pointer;
      }
    }
  }
}
