#complok-management {
  height: 100%;
  width: 100%;
  padding-top: 40px;
  background-color: #ffffff;

  &-breadcrumbs {
    padding: 40px 100px 20px;

    & .ant-breadcrumb {
      font-size: 20px;
    }
  }

  &-organization,
  &-group {
    & .ant-tabs-nav {
      margin: 0;
    }
    height: 90%;
    overflow-y: scroll;
    padding: 0 100px 100px;
  }

  &-organization {
  }

  &-group {
  }

  @media screen and (max-width: 768px) {
    &-organization,
    &-group,
    &-breadcrumbs {
      padding: 14px 14px;

      & * {
        font-size: 10px;
      }
    }
  }
}
