.complok-auth-header {
  color: transparent;
  &-language {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #b5b5be;
    margin-right: 10px;

    &-selected {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #171725;
    }
  }
}

.complok-header {
  position: fixed;
  z-index: 999;
  top: 0;
  height: 60px;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #d5d5dc;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;

  &-icon-link {
    display: flex;
    align-items: center;
    padding: 0 23px;
    & > svg {
      height: 30px;
      width: 30px;
    }
  }
  &-divider {
    height: 35px;
    width: 1px;
    background-color: #e2e2ea;
  }

  &-navigation {
    display: flex;
    align-items: center;
    padding-left: 20px;

    &-location {
      padding: 3px 15px;
      margin: 10px 0;
      & > h3 {
        margin: 0;
        padding: 0;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
      }

      &-active {
        & > h3 {
          font-weight: 500;
          color: #f99820;
        }

        border-radius: 10px;
      }
    }
  }

  &-popover {
    &:hover {
      cursor: pointer;
    }
  }

  &-selector {
    margin-right: 11px !important;
    max-width: 220px;
    font-weight: 500;
    color: #595959;
    font-size: 12px;

    &-option {
      padding: 0px 10px;
      align-items: center;

      &-icon {
        margin-right: 10px;
        margin-top: 2px;
      }
    }
  }

  &-user-name {
    background: #f1f1f5;
    border: 0.971429px solid #e2e2ea;
    height: 34px;
    width: 34px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    margin: 0 22px;
    color: #92929d;
    justify-content: center;
    align-items: center;
    font-weight: 600;
  }

  &-profile {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 22px;

    &-popover {
      margin: -12px -16px;

      &-item {
        padding: 8px 16px;
        &:hover {
          cursor: pointer;
          background-color: #e8eaed;
        }
        &-withspace {
          border-bottom: 1px solid #e8eaed;
          margin-bottom: 2px;
        }
      }
    }
  }

  &-left,
  &-right {
    display: flex;
    align-items: center;
    height: 100%;
  }
  &-right {
    justify-self: flex-end;
  }
}
