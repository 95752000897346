#complok-coursecontent-quiz {
  &-introduction {
    width: 100%;

    display: grid;
    place-items: center;
    padding: 40px 0;
    gap: 15px;

    &-title,
    &-text {
      max-width: 700px;
      text-align: center;
    }

    &-image {
      margin-bottom: 30px;
    }
    &-start {
      height: 44px;
      margin-top: 32px;
      border-radius: 15px;
    }
  }

  @media screen and (max-width: 768px) {
    &-introduction {
      padding-left: 14px;
      padding-right: 14px;

      &-title {
        text-align: center;
      }
      &-text {
        padding: 0 14px;
      }
    }

    &-question {
      padding-left: 14px;
      padding-right: 14px;

      &-title {
        font-size: 16px;
      }
    }
  }
}

.answer-checkbox {
  & .ant-checkbox {
    border-radius: 6px;
    border: 1px solid #e2e2ea;
  }
}

.wrong-answer-checkbox {
  & .ant-checkbox {
    border-radius: 6px;
    border: 1px solid #e76f51;
  }
}

.correct-answer-checkbox {
  & .ant-checkbox {
    border-radius: 6px;
    border: 1px solid #4caf50;
  }
}
