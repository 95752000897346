@import './CourseCompletion.less';
@import './CourseQuiz.less';
@import './CourseDetails.less';

#courseinfo-banner-labels {
  & svg {
    margin-right: 10px;
  }

  & > span {
    margin-right: 15px;
    color: #fff;
    white-space: nowrap;
  }
}

#complok-coursecontent-navigation-modules {
  & .anticon {
    display: block;
    line-height: 1.75;
    margin-right: 5px;
  }
  & .course-navigation-link {
    display: flex;
    margin-bottom: 10;
    justify-content: baseline;
  }

  & .ant-checkbox {
    color: #d9d9d9;
    &-checked {
      color: #fff;
    }
  }
}
