@import './StylingReset.less';
@import '../node_modules/antd/dist/antd.less';

@primary-color: '#E76F51';

.main-bg {
  background-color: @primary-color;
}

.main-color {
  color: @primary-color;
}

.main-color-white {
  @primary-color: #fff;
}

.main-svg-color > path {
  stroke: @primary-color;
}

.enable-vertical-scroll {
  overflow-y: scroll;
}

.enable-vertical-scroll::-webkit-scrollbar {
  display: none;
}

.textwhite,
h1.textwhite,
span.textwhite {
  color: #fff;
}

.fillGray svg {
  fill: #d5d5dc;
}

.slide-up-leave {
  display: none !important;
}

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'poppins';
  overflow-y: hidden;
  height: 100vh;
  width: 100vw;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.h100p {
  height: 100%;
}

#root {
  height: 100vh;
  width: 100vw;
}

.clickable {
  cursor: pointer;
}

.link-disabled {
  pointer-events: none;
}

.complok-version {
  position: absolute;
  color: lightgray;
  right: 10px;
  bottom: 10px;
}

.ant-modal-content {
  overflow: auto;
  border-radius: 10px;
}

@font-face {
  font-family: 'poppins';
  src: url(./assets/fonts/Poppins-Thin.ttf);
  font-weight: 100;
}
@font-face {
  font-family: 'poppins';
  src: url(./assets/fonts/Poppins-ExtraLight.ttf);
  font-weight: 200;
}
@font-face {
  font-family: 'poppins';
  src: url(./assets/fonts/Poppins-ExtraLightItalic.ttf);
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: 'poppins';
  src: url(./assets/fonts/Poppins-Light.ttf);
  font-weight: 300;
}
@font-face {
  font-family: 'poppins';
  src: url(./assets/fonts/Poppins-LightItalic.ttf);
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'poppins';
  src: url(./assets/fonts/Poppins-Regular.ttf);
  font-weight: 400;
}
@font-face {
  font-family: 'poppins';
  src: url(./assets/fonts/Poppins-Medium.ttf);
  font-weight: 500;
}
@font-face {
  font-family: 'poppins';
  src: url(./assets/fonts/Poppins-MediumItalic.ttf);
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'poppins';
  src: url(./assets/fonts/Poppins-SemiBold.ttf);
  font-weight: 600;
}
@font-face {
  font-family: 'poppins';
  src: url(./assets/fonts/Poppins-SemiBoldItalic.ttf);
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'poppins';
  src: url(./assets/fonts/Poppins-Bold.ttf);
  font-weight: 700;
}
@font-face {
  font-family: 'poppins';
  src: url(./assets/fonts/Poppins-BoldItalic.ttf);
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'poppins';
  src: url(./assets/fonts/Poppins-ExtraBold.ttf);
  font-weight: 800;
}
@font-face {
  font-family: 'poppins';
  src: url(./assets/fonts/Poppins-ExtraBoldItalic.ttf);
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: 'poppins';
  src: url(./assets/fonts/Poppins-Black.ttf);
  font-weight: 900;
}
@font-face {
  font-family: 'poppins';
  src: url(./assets/fonts/Poppins-BlackItalic.ttf);
  font-weight: 900;
  font-style: italic;
}

// ANT Style changes

#complok-coursecontent-navigation-modules .ant-collapse-item {
  margin: 10px 0;
  border: 1px #f1f1f5 solid;
  border-radius: 15px;
  background-color: #fafafb;
}

// Font sizes
.font20 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
}

// Complok

// // CourseDirectory
.complok-coursedirectory {
  &-list {
    &-items {
      display: flex;
      overflow-x: auto;
      margin-bottom: 20px;
    }

    &-item {
      min-height: 250px;
      min-width: min(300px, 90vw);
      max-width: 300px;
      margin-right: 30px;
      background-color: #264653;
      background-image: radial-gradient(ellipse farthest-corner at 150% 90%, #1c3c49 75%, #264653 30%);

      // #DD6547 - Dark Orange
      // #E76F51 - Light Orange
      &.dark-blue {
        background-color: #f4a261; // #264653;
        // background-image: radial-gradient(ellipse farthest-corner at 150% 90%, #1c3c49 75%, #264653 30%);
        // background-image: radial-gradient(ellipse farthest-corner at 150% 90%, #dd6547 75%, #e76f51 30%);
        background-image: radial-gradient(ellipse farthest-corner at 150% 90%, #ea9857 75%, #f4a261 30%);
      }
      &.light-blue {
        //background-color: #4784ee;
        background-color: #f4a261;
        // background-image: radial-gradient(ellipse farthest-corner at 0% -40%, #4784ee 65%, #518ef8 50%);
        // background-image: radial-gradient(ellipse farthest-corner at 0% -40%, #dd6547 65%, #e76f51 50%);
        background-image: radial-gradient(ellipse farthest-corner at 0% -40%, #ea9857 65%, #f4a261 50%);
      }

      border-radius: 15px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 20px;

      overflow: hidden;

      &-percentage.ant-typography,
      &-content > &-title.ant-typography,
      &-content > &-duedate.ant-typography {
        margin-top: 0;
        color: #fff;
      }
    }
  }
}

// // CourseContent
#complok-coursecontent {
  display: grid;
  height: 100vh;
  width: 100vw;

  grid-template-columns: 30px 1fr 30px;
  grid-template-rows: 60px 1fr;
  //grid-template-columns: 75px 1fr 75px;
  //grid-template-rows: 110px 1fr;
  place-items: flex-start;

  overflow: hidden;

  &-video {
    width: 100%;
    display: flex;
    position: relative;

    &-speed {
      display: flex;
      align-items: center;
      margin-top: 24px;
      float: right;

      &-text {
        margin-right: 10px;
        font-size: 16px;
      }
    }

    &-player {
      max-height: 80vh;
    }

    &-cover {
      position: absolute;
      height: 100%;
      width: 100%;
      border-radius: 15px;
      background-color: grey;

      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      padding: 40px;

      &-title {
        font-weight: 600;
        font-size: 36px;
        line-height: 44px;
        letter-spacing: -0.04em;
        color: #fff;
      }

      &-description {
        font-size: 14px;
        line-height: 24px;
        color: #fff;
      }

      &-duration {
        color: #fff;
      }

      &-play {
        margin-top: 50px;
        width: 90px;
        height: 44px;
      }
    }
  }

  &-quiz {
    // height: 100%;
    // overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &-question {
      width: 100%;
      padding: 32px 0px;

      &-answers {
        margin-top: 30px;
        width: 100%;
        background: #fafafb;
        border: 1px solid #e2e2ea;
        box-sizing: border-box;
        border-radius: 15px;
        padding: 31px 28px 10px;

        & .ant-checkbox {
          transform: scale(2);
          margin-right: 15px;

          & > .ant-checkbox-inner,
          &-checked::after {
            border-radius: 4px;
          }
        }

        &-answer {
          display: flex;
          align-items: center;
          margin: 10px 0;
        }
      }
      &-footer {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        &-submit {
          color: #ffffff;
          position: static;
          width: 155px;
          height: 44px;
          left: 0px;
          top: 0px;
          font-weight: 500;
          font-size: 14px;
          /* Complok Orange/orange-6 */

          background: #f4a261;
          border-radius: 50px;
        }
      }
    }

    &-result {
      // overflow-y: scroll;
      width: 100%;
      &-banner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-radius: 15px;
        padding: 30px 48px 53px;
        //padding: 60px 60px 53px;

        overflow-x: hidden;

        //background-color: #f99820;
        background-color: #f4a261;

        &.not-passed {
          background-color: #e76f51;
        }
        &.passed {
          background-color: #4caf50;
        }

        &-percent {
          height: 200px;
          font-size: 150px;
          margin: 0;
          margin-left: -16px;
          font-weight: 550;
        }
        &-main {
          &-message {
            display: block;
            margin: 15px 0 30px 0;
          }

          &-actions {
            display: flex;

            &-continue {
              margin-right: 15px;
              width: 123px;
              height: 44px;

              color: #f4a261;
              border-color: #f4a261;

              &:hover {
                font-weight: 500;
              }

              &.passed {
                color: #4caf50;
                border-color: #4caf50;
              }

              &.not-passed {
                border-color: #e76f51;
                color: #e76f51;
              }
            }
          }
        }
      }

      &-threshold {
        margin: 15px 0 30px 0;
        padding: 30px 52px;
        border-radius: 15px;
        background: #fafafb;
        border: 1px solid #e2e2ea;

        & svg {
          height: 20px;
          width: 20px;
        }
        &-result {
          margin-bottom: 30px;
        }
        &-result,
        &-requirements {
          display: flex;
          justify-content: space-between;
        }
      }

      &-questions {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &-question {
          //max-width: min(45vw, 550px);
          width: 100%;
          margin-bottom: 52px;
          min-width: 450px;
          @media screen and (max-width: 450px) {
            min-width: 100%;
          }

          &-title {
            margin-left: 5px;
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            letter-spacing: -0.02em;

            margin-bottom: 30px;
          }

          &-answers {
            background: #fafafb;
            border: 1px solid #e2e2ea;
            border-radius: 15px;
            padding: 31px 28px;

            & .success {
              color: #ffffff;
            }
            & .danger {
              color: #e76f51;
            }

            &-answer {
              margin-bottom: 20px;

              &-text {
                font-size: 16px;
                line-height: 28px;
                letter-spacing: -0.01em;
              }
              & .ant-checkbox {
                transform: scale(2);
                margin-right: 15px;
              }
              & .ant-checkbox-inner {
                border-radius: 5px;
              }

              &.gray .ant-checkbox-inner {
                background-color: #d5d5dc !important;
                border-color: #d5d5dc !important;
              }
              &.white .ant-checkbox-inner {
                background-color: #ffffff !important;
                border-color: #ffffff !important;
              }
            }
          }
        }
      }
    }
  }

  &-details {
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 30px;
    background: #fafafb;
    border: 1px solid #e2e2ea;
    box-sizing: border-box;
    border-radius: 15px;

    &-notes {
      &-list {
        display: flex;
        flex-direction: column;
        padding-top: 25px;
        &-note {
          display: flex;
          justify-content: space-between;
          padding: 15px 25px 15px 30px;
          background: #ffffff;
          border: 1px solid #f1f1f5;
          box-sizing: border-box;
          border-radius: 15px;

          margin-top: 10px;

          &-delete {
            cursor: pointer;
          }
        }
      }
    }

    &-videochapters {
      &-chapter {
        & path {
          fill: #92929d;
        }
        &:hover path {
          fill: #595959;
        }
      }
    }
  }
}
#complok-coursecontent.open-menu {
  //grid-template-columns: 75px 1fr min(30vw, 500px);
  grid-template-columns: 0 1fr min(30vw, 500px);
}

#complok-coursecontent-navigation {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}
#complok-coursecontent-navigation {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  background: #fff;

  width: 100%;
  //min-width: 420px;
  //padding: 0 75px 0 30px;
  padding: 15px 10px;
  padding-bottom: 30px;
  padding-right: 10px;

  height: 100%;
  overflow-y: auto;

  grid-row: 2/3;
  grid-column: 3/4;
  margin-right: 0;
  //margin-left: auto;
  z-index: 20;
}

#complok-coursecontent-navigation.menu-closed {
  min-width: 40px;
  //min-height: 40px;
  //max-width: 47px;
  width: 100%;
  height: 68px;
  align-items: flex-end;
  padding: 0;

  border-radius: 10px 0 0 10px;
  background-color: #fafafb;

  cursor: pointer;

  & > svg {
    margin: 8px 11px;
    margin-top: 23px;
  }
}

#complok-coursecontent-main {
  grid-row: 2/3;
  grid-column: 2/3;
  padding: 20px;
}

#complok-coursecontent-main::-webkit-scrollbar {
  display: none;
}

.complok-coursedirectory-list-items::-webkit-scrollbar {
  display: none;
}

#complok-coursecontent-navigation .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #52c41a !important;
  border-color: #52c41a !important;
}

#complok-coursecontent-navigation .ant-checkbox-inner {
  border-radius: 100%;
}

#complok-quiz-result-questions-question-answers-answer-text {
  font-weight: 500;
  font-size: 16px;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }

  &.correct {
    color: #4caf50 !important;
  }

  &.incorrect {
    color: #f5222d !important;
  }
}

@import './screens/SuperAdmin/SuperAdmin.less';
@import './screens/Admin/Admin.less';
@import './screens/Learner/Learner.less';

@primary-color: #F99820;@border-radius-base: 7px;