#complok-questionnaire {
  &-banner {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;

    padding: 60px 52px;
    border-radius: 15px;

    background: #e8a66c;

    &.success {
      background: #4caf50;
    }
    &.failure {
      background: #e76f51;
    }

    &-percentage {
      color: #fff;
      font-size: 128px;
      margin: 0;
    }
    &-message {
      &-title {
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 44px;
        letter-spacing: -0.04em;
      }
      &-description {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: -0.01em;
      }
    }
    &-actions {
      margin-top: 24px;

      &-continue {
        &.success {
          color: #4caf50;
          &:hover {
            border-color: #4caf50;
          }
        }
        &.neutral {
          color: #e8a66c;
        }

        &.failure {
          color: #e76f51;
        }
      }
      &-retry {
        color: #fff;

        &.failure {
          color: #e76f51;
        }
        &.failure.ant-btn-text {
          color: #fff;
        }

        &.neutral {
          color: #fff;
        }
      }
    }
    @media screen and (max-width: 768px) {
      min-height: 450px;
      border-radius: 0;
      padding: 29px 15px;
      overflow: hidden;

      &-percentage {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        margin-top: 168px;

        &-watermark {
          font-size: 13rem;
          position: absolute;
          left: -5%;
          top: -5%;
          line-height: 0.9;
          opacity: 0.1;
          color: #fff;
        }
      }
    }
  }

  &-questions {
  }
}
