#complok-coursecontent-details {
  &-notes {
    &-addnote {
      position: relative;
      transition-duration: 0.2s;
      margin-bottom: 25px;

      &-field {
        background: #ffffff;
        border: 1px solid #d5d5dc;
        box-sizing: border-box;
        border-radius: 10px;
        width: 100%;
        padding: 8px 12px;
        margin-bottom: 15px;
      }
      &-add {
      }
    }
  }
}
