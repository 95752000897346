@import './QuestionnaireComponents/Questionnaire.less';

#complok-coursecontent-completion {
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-columns: 1fr 1fr;

  &-content {
    display: grid;
    align-content: center;
    justify-content: start;
    padding-left: 64px;

    &-title {
      margin-bottom: 15px;
      font-weight: 600;
      font-size: 36px;
      line-height: 44px;
    }
    &-text {
      font-size: 16px;
      line-height: 28px;
      text-align: left;
      width: 100%;
      margin-top: 10px;
    }

    &-actions {
      display: grid;
      gap: 10px;
      padding-bottom: 120px;

      & button {
        display: flex;
        align-items: center;
        width: fit-content;
        height: 44px;
        border-radius: 15px;
        padding: 0 30px;
        font-size: 14px;
        & .anticon {
          font-size: 18px;
          line-height: 0;
        }
      }

      &-certificate {
      }
    }
  }

  &-image {
    display: grid;
    background: radial-gradient(circle farthest-corner at -30% 40%, #172b4d 85%, transparent 85.2%);

    & > svg {
      height: 70%;
      margin: auto auto auto 5%;
    }
  }
}
