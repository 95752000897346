#complok-learner-certificates {
  & .certificate-list-item {
    height: 250px;
    width: 250px;
    background-color: #f99820;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    //padding: 15px;

    &-title {
      padding: 15px;
    }

    &-action.ant-typography {
      color: #2c2219;
      text-align: end;
      padding: 15px;
      margin: 0;

      border-radius: 0 0 15px 15px;
    }
    &:hover &-action {
      color: #fff;
      background-color: #2c2219;
    }
  }
}
