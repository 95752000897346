@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

@include keyframes(slide-down) {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

$button-type: "primary", "secondary", "danger";

$colors: (
  "primary": (
    default: #f99820,
    hover: #d77804,
    disabled: #fccc90,
  ),
  "secondary": (
    default: #92929d,
    hover: #595959,
    disabled: #d5d5dc,
  ),
  "danger": (
    default: #f5222d,
    hover: #cf1322,
    disabled: #ffa39e,
  ),
);

body {
  font-family: "poppins";
}

.complok {
  &-loader {
    @include animation("slide-down 5s 3");
  }

  &-text-input {
    background: #ffffff;
    border: 1px solid #d5d5dc;
    box-sizing: border-box;
    border-radius: 10px;
  }

  &-minus-plus-container {
    padding: 5px 15px;
    width: 39px;
    height: 34px;
    background: #e2e2ea;
    border-radius: 50px;
    cursor: pointer;
    margin: 0px 5px;
  }
  &-active-container {
    padding: 5px 15px;
    width: 39px;
    height: 34px;
    background: #f99820;
    border-radius: 50px;
    cursor: pointer;
    margin: 0px 5px;
  }
  &-ui-card {
    padding: 30px;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 15px;

    &-header {
      font-family: "poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.02em;
      color: #000000;
    }

    &-children {
      margin-top: 10px;
    }
  }

  &-button {
    font-family: "poppins";
    color: #ffffff;
    padding: 2px 10px;
    border-radius: 50px;
    font-weight: normal;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    line-height: 28px;

    &-icon {
      margin-left: 11px;
    }

    &-container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 10px;
    }

    &-small {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      padding: 2px 10px;
      display: block;
      height: fit-content;
    }

    &-default {
      padding: 8px 16px;
      height: auto;
      font-weight: 600;
    }

    @each $typeof-color, $color in $colors {
      &-#{$typeof-color},
      &-#{$typeof-color}.ant-btn {
        background: map-get($color, "default") !important;
        border-color: map-get($color, "default");
        &:active,
        &:focus {
          background: map-get($color, "default") !important;
          border-color: map-get($color, "default");
        }
        &:hover,
        &:link,
        &:visited {
          color: #fff;
          background: map-get($color, "hover") !important;
          border-color: map-get($color, "hover");
        }
        &:disabled {
          color: #fff;
          background: map-get($color, "disabled") !important;
          border-color: map-get($color, "disabled");
        }
      }
      &-#{$typeof-color}.ant-btn-text,
      &-#{$typeof-color}.ant-btn-link {
        background: none !important;
        border: 0;
        color: map-get($color, "default");
        &:active,
        &:focus,
        &:hover,
        &:link,
        &:visited {
          background: none !important;
          border: 0 !important;
          color: map-get($color, "hover") !important;
        }
        &:disabled {
          color: map-get($color, "disabled") !important;
        }
      }
    }
  }

  &-tag {
    font-family: "poppins";
    color: #ffffff;
    padding: 1px 9px;
    font-weight: bold;
    border-radius: 50px;

    &-default,
    &-primary {
      // TODO: Keeping both classes until we keep one
      color: #44444f; // map-get(map-get($colors, 'secondary'), 'default');
      border-color: #d5d5dc;
      background-color: #fafafb;
    }
    &-success {
      color: #52c41a;
      border-color: #52c41a;
      background-color: #f6ffed;
    }
    &-warning {
      color: map-get(map-get($colors, "primary"), "default");
      border-color: #fbb763;
      background-color: #feead2;
    }
    &-danger {
      background-color: #fff1f0;
      border-color: #ff4d4f;
      color: #cf1322;
    }
  }

  &-checkbox {
    display: block;
    position: relative;

    height: 30px;
    width: 30px;
    margin: 10px;

    background-size: 10px;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;

    background-color: #f1f1f5;
    border-color: #f1f1f5;
    border-radius: 5px;

    & input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    &-icon:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    & input:checked ~ &-icon:after {
      display: block;
    }

    &-checked > &-icon {
      filter: brightness(0);
      position: absolute;
      height: 100%;
      width: 100%;
      background-image: url("../components/icons/checkboxTick.svg");
      background-size: 10px;
      background-position: center;
      background-repeat: no-repeat;
    }

    &-correct {
      background-color: #52c41a;
      border-color: #52c41a;
      background-image: url("../components/icons/checkboxTick.svg");
    }

    &-incorrect {
      background-color: #f5222d;
      border-color: #f5222d;

      background-image: url("../components/icons/cross.svg");
    }

    &-md {
      position: absolute;
      opacity: 0;
    }
  }

  &-table {
    background: #f1f1f5;
    border-radius: 15px;
    width: 100%;
    padding: 10px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    &-expanded {
      background: #f1f1f5;
      border-radius: 15px;
      width: 100%;
      padding: 10px;
      justify-content: center;
      display: flex;
      flex-direction: column;
    }
  }

  &-dropdown {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    & > svg {
      position: absolute;
      padding-right: 10px;
      pointer-events: none;
    }
    & > select {
      background: #ffffff;
      padding: 5px 16px;

      border: 1px solid #d5d5dc;
      box-sizing: border-box;
      border-radius: 10px;

      outline: none;

      // Reset defaults
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      // MS Explorer
      &::-ms-expand {
        display: none;
      }
    }
  }
}

.complok-toggle.react-toggle--checked .react-toggle-track {
  background-color: #52c41a;
}
