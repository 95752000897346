.complok-icon {
  &-close {
    & > path {
      stroke: #92929d;
    }
    &:hover > path {
      stroke: #171725;
    }
  }
  &-arrow {
    & > path {
      stroke: #fff;
    }
  }
}
